.map__container {
  margin: 20px auto 0;
  position: relative;
}

.button-mesa {
  min-width: 55px;
  margin: 7px;
  border: none;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  background-color: #e0e0e0;
}

button.button-mesa.turno1 {
  background-color: #f50057;
  color: white;
}

button.button-mesa.turno2 {
  background-color: #3f51b5;
  color: white;
}

button.button-mesa.two-colors {
  color: white;
  background-image: linear-gradient(to right, #3f51b5, rgba(255,0,0,1));
}

button.button-mesa.digits {
  padding: 15px;
}

h1.MuiTypography-root.MuiTypography-h5 {
  margin-bottom: 10px;
}

li.MuiBreadcrumbs-separator {
  margin: 0;
}

.MuiBreadcrumbs-root.MuiTypography-body1.MuiTypography-colorTextSecondary {
  margin-bottom: 20px;
}

a.MuiTypography-root.MuiLink-root.MuiLink-underlineHover.MuiTypography-colorPrimary {
  color: #007bff;
}

.button-mesa__container {
  margin-top: 10px;
}

.turno2-text {
  margin-left: 15px;
}

.mesa__map {
  margin-left: 15px;
}

.detail__reserva-modal {
  display: block;
}

.total-personas__container {
  text-align: center;
}

.MuiTextField-root.total-personas {
  width: 56px;
}

.total__label {
  display: block;
  color: #777;
}

.MuiOutlinedInput-inputMarginDense {
  text-align: center;
}
