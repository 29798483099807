.avatar {
  margin: 8px;
  background-color: rgb(220, 0, 78) !important;
}

.login-container {
  width: 100%;
  margin-top: 24px;
}

.button__save {
  margin: 24px 0px 16px !important;
}
